export const SortBarSelectStyles = {
  container: base => ({
    ...base,
    zIndex: 4,
    width: 'fit-content',
  }),
  control: base => ({
    ...base,
    minHeight: 'fit-content',
    border: 'none',
    backgroundColor: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    width: 10,
    alignSelf: 'center',
  }),
  dropdownIndicator: (base, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...base,
      border: 'solid #000000',
      padding: 3,
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      transform: menuIsOpen ? 'rotate(-135deg)' : 'rotate(45deg)',
      borderColor: '#5A88C6',
      '& > svg': {
        display: 'none',
      },
      marginLeft: 5,
      marginTop: menuIsOpen ? 5 : 0,
    };
  },
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
  menu: base => ({
    ...base,
    padding: 0,
    minWidth: 145,
    width: 'fit-content',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    minWidth: 145,
    width: 'fit-content',
  }),
  singleValue: base => ({
    ...base,
    maxWidth: 'calc(100% - 5px)',
    marginLeft: 10,
    marginRight: 7,
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    color: '#5A88C6',
    transform: 'none',
    position: 'static',
  }),
  option: base => ({
    ...base,
    fontSize: 16,
    width: '100%',
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
    padding: '8px 12px 8px 16px',
    height: 'auto',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(185, 199, 212, 0.3)',
      color: '#5A88C6',
    },
    color: '#404040',
    cursor: 'pointer',
  }),
};
