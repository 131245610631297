export const removeTitleFromHTML = (html: string) =>
  html.replace(/<(\w+)>(\s)*Overview(\s)*<\/\1>/, '').replace(/<(\w+)>(&nbsp;)*<\/\1>/, '');

type TcreateJsonForTooltipProps = {
  text: string;
  tooltipText: string;
};

export const createJsonForTooltip = ({ text = '', tooltipText = '' }: TcreateJsonForTooltipProps) =>
  JSON.stringify({
    header: text,
    text: tooltipText,
  });

export const getTime = (str: string) => {
  return str?.replace(/(\d+ \w*) (.*)/, '$1') || '';
};

export const getText = (str: string) => {
  return getTime(str) !== str ? str?.replace(/(\d+ \w*) (.*)/, '$2') : '';
};
