import React from 'react';
import closeButton from '../../../../static/close.svg';

export const ClearIndicator = (props: any): React.ReactElement => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <img
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
      src={closeButton}
      alt="clear"
    />
  );
};
