import { HoverTooltipsOptions } from '@components/HoverTooltip/types';
import { statuses, statusTooltips } from './constants';
import { LabelType } from './types';

export const getStatusText = (statusName: string): string => {
  return statusName ? statuses[statusName] : '';
};

export const getStatusTooltip = (
  statusName: string,
  type: LabelType,
): HoverTooltipsOptions | null => {
  return statusTooltips[type] ? statusTooltips[type][statusName] : null;
};
