import {
  INDEX_PAGE_URI,
  REGION_AFRICA_URI,
  REGION_AMERICAS_URI,
  REGION_ASIA_URI,
  REGION_EUROPE_URI,
  REGION_MIDDLE_EAST_URI,
  REGION_STATES_URI,
} from 'constants/pageUris';
import { Item } from '@components/SortBar/types';

export const contentMenuItems = [
  {
    label: 'Top destinations',
    uri: INDEX_PAGE_URI,
  },
  {
    label: 'U.S. States',
    uri: REGION_STATES_URI,
  },
  {
    label: 'Americas',
    uri: REGION_AMERICAS_URI,
  },
  {
    label: 'Europe',
    uri: REGION_EUROPE_URI,
  },
  {
    label: 'Africa',
    uri: REGION_AFRICA_URI,
  },
  {
    label: 'MENA',
    uri: REGION_MIDDLE_EAST_URI,
  },
  {
    label: 'Asia',
    uri: REGION_ASIA_URI,
  },
];

export const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'countryName',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

export const homeSortByItem: Item[] = [
  ...sortByItem,
  {
    label: 'Popularity',
    fieldName: 'topDestination',
  },
];

export const nonRomanLang = /ru|ja|zh/;

export const translationPlaceholder = {
  en: 'Country or U.S. State',
  zh: '國家或美國州',
  ru: 'Страна или штат США',
  ja: '国または米国の州',
};

export const translationNamesCountries: { [key: string]: { [key: string]: string } } = {
  AF: { zh: '阿富汗', ru: 'Афганистан', ja: 'アフガニスタン' },
  AL: { zh: '阿尔巴尼亚', ru: 'Албания', ja: 'アルバニア' },
  DZ: { zh: '阿尔及利亚', ru: 'Алжир', ja: 'アルジェリア' },
  AO: { zh: '安哥拉', ru: 'Ангола', ja: 'アンゴラ' },
  AG: { zh: '安提瓜和巴布达', ru: 'Антигуа и Барбуда', ja: 'アンティグア・バーブーダ' },
  AR: { zh: '阿根廷', ru: 'Аргентина', ja: 'アルゼンチン' },
  AM: { zh: '亚美尼亚', ru: 'Армения', ja: 'アルメニア' },
  AU: { zh: '澳大利亚', ru: 'Австралия', ja: 'オーストラリア' },
  AT: { zh: '奥地利', ru: 'Австрия', ja: 'オーストリア' },
  AZ: { zh: '阿塞拜疆', ru: 'Азербайджан', ja: 'アゼルバイジャン' },
  BS: { zh: '巴哈马', ru: 'Багамские острова', ja: 'バハマ' },
  BH: { zh: '巴林', ru: 'Бахрейн', ja: 'バーレーン' },
  BD: { zh: '孟加拉国', ru: 'Бангладеш', ja: 'バングラデシュ' },
  BB: { zh: '巴巴多斯', ru: 'Барбадос', ja: 'バルバドス' },
  BY: { zh: '白俄罗斯', ru: 'Беларусь', ja: 'ベラルーシ' },
  BE: { zh: '比利时', ru: 'Бельгия', ja: 'ベルギー' },
  BJ: { zh: '贝宁', ru: 'Бенин', ja: 'ベニン' },
  BM: { zh: '百慕大', ru: 'Бермудские острова', ja: 'バーミューダ' },
  BT: { zh: '不丹', ru: 'Бутан', ja: 'ブータン' },
  BO: { zh: '玻利维亚', ru: 'Боливия', ja: 'ボリビア' },
  BA: { zh: '波斯尼亚 - 黑塞哥维那', ru: 'Босния и Герцеговина', ja: 'ボスニヤ・ヘルツェゴビナ' },
  BW: { zh: '博茨瓦纳', ru: 'Ботсвана', ja: 'ボツワナ' },
  BR: { zh: '巴西', ru: 'Бразилия', ja: 'ブラジル' },
  BN: { zh: '文莱达鲁萨兰国', ru: 'Бруней-Даруссалам', ja: 'ブルネイ' },
  BG: { zh: '保加利亚', ru: 'Болгария', ja: 'ブルガリア' },
  BF: { zh: '布基纳法索', ru: 'Буркина-Фасо', ja: 'ブルキナファソ' },
  BI: { zh: '布隆迪', ru: 'Бурунди', ja: 'ブルンジ' },
  KH: { zh: '柬埔寨', ru: 'Камбоджа', ja: 'カンボジア' },
  CM: { zh: '喀麦隆', ru: 'Камерун', ja: 'カメルーン' },
  CA: { zh: '加拿大', ru: 'Канада', ja: 'カナダ' },
  CV: { zh: '佛得角', ru: 'Кабо-Верде', ja: 'カーボベルデ' },
  KY: { zh: '开曼群岛', ru: 'Острова Кайман', ja: 'ケイマン諸島' },
  CF: { zh: '中非共和国', ru: 'Центральноафриканская Республика', ja: '中央アフリカ共和国' },
  TD: { zh: '乍得', ru: 'Чад', ja: 'チャド' },
  CL: { zh: '智利', ru: 'Чили', ja: 'チリ' },
  CN: { zh: '中国', ru: 'Китай', ja: '中国' },
  CO: { zh: '哥伦比亚', ru: 'Колумбия', ja: 'コロンビア' },
  KM: { zh: '科摩罗', ru: 'Коморские острова', ja: 'コモロ' },
  CG: { zh: '刚果（布）', ru: 'Конго (Браззавиль)', ja: 'コンゴ（ブラザビル）' },
  CD: { zh: '刚果（金）', ru: 'Конго (Киншаса)', ja: 'コンゴ（キンシャサ）' },
  CR: { zh: '哥斯达黎加', ru: 'Коста-Рика', ja: 'コスタリカ' },
  HR: { zh: '克罗地亚', ru: 'Хорватия', ja: 'クロアチア' },
  CU: { zh: '古巴', ru: 'Куба', ja: 'キューバ' },
  CY: { zh: '塞浦路斯', ru: 'Кипр', ja: 'キプロス' },
  CZ: { zh: '捷克共和国', ru: 'Чехия', ja: 'チェコ共和国' },
  CI: { zh: '科特迪瓦', ru: 'Кот-д’Ивуар', ja: 'コートジボワール' },
  DK: { zh: '丹麦', ru: 'Дания', ja: 'デンマーク' },
  DJ: { zh: '吉布提', ru: 'Джибути', ja: 'ジブチ' },
  DO: { zh: '多明尼加共和国', ru: 'Доминиканская Республика', ja: 'ドミニカ共和国' },
  EC: { zh: '厄瓜多尔', ru: 'Эквадор', ja: 'エクアドル' },
  EG: { zh: '埃及', ru: 'Египет', ja: 'エジプト' },
  SV: { zh: '萨尔瓦多', ru: 'Эль Сальвадор', ja: 'エルサルバドル' },
  GQ: { zh: '赤道几内亚', ru: 'Экваториальная Гвинея', ja: '赤道ギニア' },
  ER: { zh: '厄立特里亚', ru: 'Эритрея', ja: 'エリトリア' },
  EE: { zh: '爱沙尼亚', ru: 'Эстония', ja: 'エストニア' },
  ET: { zh: '埃塞俄比亚', ru: 'Эфиопия', ja: 'エチオピア' },
  FJ: { zh: '斐', ru: 'Фиджи', ja: 'フィジー' },
  FI: { zh: '芬兰', ru: 'Финляндия', ja: 'フィンランド' },
  FR: { zh: '法国', ru: 'Франция', ja: 'フランス' },
  GA: { zh: '加蓬', ru: 'Габон', ja: 'ガボン' },
  GM: { zh: '冈比亚', ru: 'Гамбия', ja: 'ガンビア' },
  GE: { zh: '格鲁吉亚', ru: 'Грузия', ja: 'ジョージア' },
  DE: { zh: '德国', ru: 'Германия', ja: 'ドイツ' },
  GH: { zh: '加纳', ru: 'Гана', ja: 'ガーナ' },
  GR: { zh: '希腊', ru: 'Греция', ja: 'ギリシャ' },
  GD: { zh: '格林纳达', ru: 'Гренада', ja: 'グレナダ' },
  GT: { zh: '危地马拉', ru: 'Гватемала', ja: 'グアテマラ' },
  GN: { zh: '几内亚', ru: 'Гвинея', ja: 'ギニア' },
  GW: { zh: '几内亚比绍', ru: 'Гвинея-Бисау', ja: 'ギニアビサウ' },
  GY: { zh: '圭亚那', ru: 'Гайана', ja: 'ガイアナ' },
  HT: { zh: '海地', ru: 'Гаити', ja: 'ハイチ' },
  HN: { zh: '洪都拉斯', ru: 'Гондурас', ja: 'ホンジュラス' },
  HK: { zh: '香港', ru: 'Гонконг', ja: '香港' },
  HU: { zh: '匈牙利', ru: 'Венгрия', ja: 'ハンガリー' },
  IS: { zh: '冰岛', ru: 'Исландия', ja: 'アイスランド' },
  IN: { zh: '印度', ru: 'Индия', ja: 'インド' },
  ID: { zh: '印度尼西亚', ru: 'Индонезия', ja: 'インドネシア' },
  IQ: { zh: '伊拉克', ru: 'Ирак', ja: 'イラク' },
  IE: { zh: '爱尔兰', ru: 'Ирландия', ja: 'アイルランド' },
  IR: { zh: '伊朗伊斯兰共和国', ru: 'Исламская Республика Иран', ja: 'イラン・イスラム共和国' },
  IL: { zh: '以色列', ru: 'Израиль', ja: 'イスラエル' },
  IT: { zh: '意大利', ru: 'Италия', ja: 'イタリア' },
  JM: { zh: '牙买加', ru: 'Ямайка', ja: 'ジャマイカ' },
  JP: { zh: '日本', ru: 'Япония', ja: '日本' },
  JO: { zh: '约旦', ru: 'Иордания', ja: 'ヨルダン' },
  KZ: { zh: '哈萨克斯坦', ru: 'Казахстан', ja: 'カザフスタン' },
  KE: { zh: '肯尼亚', ru: 'Кения', ja: 'ケニア' },
  SZ: { zh: '史瓦帝尼', ru: 'Королевство Эсватини', ja: 'Eswatiniの王国' },
  KR: { zh: '大韩民国', ru: 'Республика Корея', ja: '大韓民国' },
  XK: { zh: '科索沃', ru: 'Kosovo', ja: 'コソボ' },
  KW: { zh: '科威特', ru: 'Кувейт', ja: 'クウェート' },
  KG: { zh: '吉尔吉斯斯坦', ru: 'Киргизия', ja: 'キルギス' },
  LA: { zh: '老挝', ru: 'Лаос', ja: 'ラオス' },
  LV: { zh: '拉脱维亚', ru: 'Латвия', ja: 'ラトビア' },
  LB: { zh: '黎巴嫩', ru: 'Ливан', ja: 'レバノン' },
  LS: { zh: '莱索托', ru: 'Лесото', ja: 'レソト' },
  LR: { zh: '利比里亚', ru: 'Либерия', ja: 'リベリア' },
  LY: { zh: '利比亚', ru: 'Ливия', ja: 'リビア' },
  LI: { zh: '列支敦士登', ru: 'Лихтенштейн', ja: 'リヒテンシュタイン' },
  LT: { zh: '立陶宛', ru: 'Литва', ja: 'リトアニア' },
  LU: { zh: '卢森堡', ru: 'Люксембург', ja: 'ルクセンブルク' },
  MO: { zh: '澳门', ru: 'Макао', ja: 'マカオ' },
  MG: { zh: '马达加斯加', ru: 'Мадагаскар', ja: 'マダカスカル' },
  MW: { zh: '马拉维', ru: 'Малави', ja: 'マラウイ' },
  MY: { zh: '马来西亚', ru: 'Малайзия', ja: 'マレーシア' },
  MV: { zh: '马尔代夫', ru: 'Мальдивы', ja: 'モルディブ' },
  ML: { zh: '马里', ru: 'Мали', ja: 'マリ' },
  MT: { zh: '马耳他', ru: 'Мальта', ja: 'マルタ' },
  MR: { zh: '毛里塔尼亚', ru: 'Мавритания', ja: 'モーリタニア' },
  MU: { zh: '毛里求斯', ru: 'Маврикий', ja: 'モーリシャス' },
  MX: { zh: '墨西哥', ru: 'Мексика', ja: 'メキシコ' },
  MD: { zh: '摩尔多瓦', ru: 'Молдова', ja: 'モルドバ' },
  MC: { zh: '摩纳哥', ru: 'Монако', ja: 'モナコ' },
  MN: { zh: '蒙古', ru: 'Монголия', ja: 'モンゴル' },
  ME: { zh: '黑山', ru: 'Черногория', ja: 'モンテネグロ' },
  MA: { zh: '摩洛哥', ru: 'Марокко', ja: 'モロッコ' },
  MZ: { zh: '莫桑比克', ru: 'Мозамбик', ja: 'モザンビーク' },
  MM: { zh: '缅甸', ru: 'Мьянма', ja: 'ビルマ' },
  NA: { zh: '纳米比亚', ru: 'Намибия', ja: 'ナミビア' },
  NP: { zh: '尼泊尔', ru: 'Непал', ja: 'ネパール' },
  NL: { zh: '荷兰', ru: 'Нидерланды', ja: 'オランダ' },
  NZ: { zh: '新西兰', ru: 'Новая Зеландия', ja: 'ニュージーランド' },
  NI: { zh: '尼加拉瓜', ru: 'Никарагуа', ja: 'ニカラグア' },
  NE: { zh: '尼日尔', ru: 'Нигер', ja: 'ニジェール' },
  NG: { zh: '尼日利亚', ru: 'Нигерия', ja: 'ナイジェリア' },
  NO: { zh: '挪威', ru: 'Норвегия', ja: 'ノルウェー' },
  OM: { zh: '阿曼', ru: 'Оман', ja: 'オマーン' },
  PK: { zh: '巴基斯坦', ru: 'Пакистан', ja: 'パキスタン' },
  PA: { zh: '巴拿马', ru: 'Панама', ja: 'パナマ' },
  PG: { zh: '巴布亚新几内亚', ru: 'Папуа - Новая Гвинея', ja: 'パプアニューギニア' },
  PY: { zh: '巴拉圭', ru: 'Парагвай', ja: 'パラグアイ' },
  PE: { zh: '秘鲁', ru: 'Перу', ja: 'ペルー' },
  PH: { zh: '菲律宾', ru: 'Филиппины', ja: 'フィリピン' },
  PL: { zh: '波兰', ru: 'Польша', ja: 'ポーランド' },
  PT: { zh: '葡萄牙', ru: 'Португалия', ja: 'ポルトガル' },
  QA: { zh: '卡塔尔', ru: 'Катар', ja: 'カタール' },
  MK: { zh: '马其顿共和国', ru: 'Республика Македония', ja: 'マケドニア共和国' },
  RO: { zh: '罗马尼亚', ru: 'Румыния', ja: 'ルーマニア' },
  RU: { zh: '俄罗斯联邦', ru: 'Российская Федерация', ja: 'ロシア連邦' },
  RW: { zh: '卢旺达', ru: 'Руанда', ja: 'ルワンダ' },
  ST: { zh: '圣多美和普林西比', ru: 'Сан-Томе и Принсипи', ja: 'サントメ・プリンシペ' },
  SA: { zh: '沙特阿拉伯', ru: 'Саудовская Аравия', ja: 'サウジアラビア' },
  SN: { zh: '塞内加尔', ru: 'Сенегал', ja: 'セネガル' },
  RS: { zh: '塞尔维亚', ru: 'Сербия', ja: 'セルビア' },
  SC: { zh: '塞舌尔', ru: 'Сейшельские острова', ja: 'セイシェル' },
  SL: { zh: '塞拉利昂', ru: 'Сьерра-Леоне', ja: 'シエラレオネ' },
  SG: { zh: '新加坡', ru: 'Сингапур', ja: 'シンガポール' },
  GS: {
    zh: '南乔治亚岛和南桑威奇群岛',
    ru: 'Южная Джорджия и Южные Сандвичевы острова',
    ja: 'サウスジョージア・サウスサンドウィッチ諸島',
  },
  SK: { zh: '斯洛伐克', ru: 'Словакия', ja: 'スロバキア' },
  SI: { zh: '斯洛文尼亚', ru: 'Словения', ja: 'スロベニア' },
  SO: { zh: '索马里', ru: 'Сомали', ja: 'ソマリア' },
  ZA: { zh: '南非', ru: 'Южная Африка', ja: '南アフリカ' },
  SS: { zh: '南苏丹', ru: 'Южный Судан', ja: '南スーダン' },
  ES: { zh: '西班牙', ru: 'Испания', ja: 'スペイン' },
  LK: { zh: '斯里兰卡', ru: 'Шри Ланка', ja: 'スリランカ' },
  SD: { zh: '苏丹', ru: 'Судан', ja: 'スーダン' },
  SR: { zh: '苏里南', ru: 'Суринам', ja: 'スリナム' },
  SE: { zh: '瑞典', ru: 'Швеция', ja: 'スウェーデン' },
  CH: { zh: '瑞士', ru: 'Швейцария', ja: 'スイス' },
  SY: { zh: '叙利亚', ru: 'Сирия', ja: 'シリア' },
  TW: { zh: '台湾', ru: 'Тайвань', ja: '台湾' },
  TJ: { zh: '塔吉克斯坦', ru: 'Таджикистан', ja: 'タジキスタン' },
  TH: { zh: '泰国', ru: 'Таиланд', ja: 'タイ' },
  TG: { zh: '多哥', ru: 'Того', ja: 'トーゴ' },
  TT: { zh: '特立尼达和多巴哥', ru: 'Тринидад и Тобаго', ja: 'トリニダード・トバゴ' },
  TN: { zh: '突尼斯', ru: 'Тунис', ja: 'チュニジア' },
  TR: { zh: '土耳其共和国', ru: 'Турецкая Республика', ja: 'トルコ共和国' },
  UG: { zh: '乌干达', ru: 'Уганда', ja: 'ウガンダ' },
  UA: { zh: '乌克兰', ru: 'Украина', ja: 'ウクライナ' },
  AE: { zh: '阿拉伯联合酋长国', ru: 'Объединенные Арабские Эмираты', ja: 'アラブ首長国連邦' },
  GB: { zh: '英国', ru: 'Великобритания', ja: 'イギリス' },
  TZ: {
    zh: '坦桑尼亚联合共和国',
    ru: 'Объединенная Республика Танзания',
    ja: 'タンザニア連合共和国',
  },
  US: { zh: '美国', ru: 'Соединенные Штаты', ja: 'アメリカ' },
  UY: { zh: '乌拉圭', ru: 'Уругвай', ja: 'ウルグアイ' },
  UZ: { zh: '乌兹别克斯坦', ru: 'Узбекистан', ja: 'ウズベキスタン' },
  VE: { zh: '委内瑞拉', ru: 'Венесуэла', ja: 'ベネズエラ' },
  VN: { zh: '越南', ru: 'Вьетнам', ja: 'ベトナム' },
  YE: { zh: '也门', ru: 'Йемен', ja: 'イエメン' },
  ZM: { zh: '赞比亚', ru: 'Замбия', ja: 'ザンビア' },
  ZW: { zh: '津巴布韦', ru: 'Зимбабве', ja: 'ジンバブエ' },
};

export const translationNamesStates: { [key: string]: { [key: string]: string } } = {
  AL: { zh: '阿拉巴马', ru: 'Алабама', ja: 'アラバマ州' },
  AK: { zh: '阿拉斯加州', ru: 'Аляска', ja: 'アラスカ' },
  AZ: { zh: '亚利桑那', ru: 'Аризона', ja: 'アリゾナ州' },
  AR: { zh: '阿肯色州', ru: 'Арканзас', ja: 'アーカンソー州' },
  CA: { zh: '加州', ru: 'Калифорния', ja: 'カリフォルニア' },
  CO: { zh: '科罗拉多州', ru: 'Колорадо', ja: 'コロラド州' },
  CT: { zh: '康涅狄格', ru: 'Коннектикут', ja: 'コネチカット' },
  DE: { zh: '特拉华', ru: 'Делавэр', ja: 'デラウェア' },
  FL: { zh: '佛罗里达', ru: 'Флорида', ja: 'フロリダ' },
  GA: { zh: '格鲁吉亚', ru: 'Джорджия', ja: 'ジョージア' },
  HI: { zh: '夏威夷', ru: 'Гавайи', ja: 'ハワイ' },
  ID: { zh: '爱达荷州', ru: 'Айдахо', ja: 'アイダホ州' },
  IL: { zh: '伊利诺伊', ru: 'Иллинойс', ja: 'イリノイ州' },
  IN: { zh: '印地安那', ru: 'Индиана', ja: 'インディアナ' },
  IA: { zh: '爱荷华州', ru: 'Айова', ja: 'アイオワ州' },
  KS: { zh: '堪萨斯', ru: 'Канзас', ja: 'カンザス' },
  KY: { zh: '肯塔基', ru: 'Кентукки', ja: 'ケンタッキー州' },
  LA: { zh: '路易斯安那州', ru: 'Луизиана', ja: 'ルイジアナ州' },
  ME: { zh: '缅因州', ru: 'Мэн', ja: 'メイン州' },
  MD: { zh: '马里兰', ru: 'Мэриленд', ja: 'メリーランド州' },
  MA: { zh: '马萨诸塞', ru: 'Массачусетс', ja: 'マサチューセッツ州' },
  MI: { zh: '密歇根州', ru: 'Мичиган', ja: 'ミシガン州' },
  MN: { zh: '明尼苏达', ru: 'Миннесота', ja: 'ミネソタ州' },
  MS: { zh: '密西西比州', ru: 'Миссисипи', ja: 'ミシシッピ' },
  MO: { zh: '密苏里州', ru: 'Миссури', ja: 'ミズーリ' },
  MT: { zh: '蒙大拿', ru: 'Монтана', ja: 'モンタナ' },
  NE: { zh: '内布拉斯加州', ru: 'Небраска', ja: 'ネブラスカ州' },
  NV: { zh: '内华达', ru: 'Невада', ja: 'ネバダ' },
  NH: { zh: '新罕布什尔', ru: 'Нью-Гемпшир', ja: 'ニューハンプシャー' },
  NJ: { zh: '新泽西州', ru: 'Нью-Джерси', ja: 'ニュージャージー' },
  NM: { zh: '新墨西哥', ru: 'Нью-Мексико', ja: 'ニューメキシコ州' },
  NY: { zh: '纽约', ru: 'Нью-Йорк', ja: 'ニューヨーク' },
  NC: { zh: '北卡罗来纳', ru: 'Северная Каролина', ja: 'ノースカロライナ州' },
  ND: { zh: '北达科他州', ru: 'Северная Дакота', ja: 'ノースダコタ州' },
  OH: { zh: '俄亥俄州', ru: 'Огайо', ja: 'オハイオ州' },
  OK: { zh: '俄克拉何马州', ru: 'Оклахома', ja: 'オクラホマ州' },
  OR: { zh: '俄勒冈', ru: 'Орегон', ja: 'オレゴン' },
  PA: { zh: '宾夕法尼亚', ru: 'Пенсильвания', ja: 'ペンシルバニア' },
  RI: { zh: '罗德岛', ru: 'Род-Айленд', ja: 'ロードアイランド' },
  SC: { zh: '南卡罗来纳', ru: 'Южная Каролина', ja: 'サウスカロライナ' },
  SD: { zh: '南达科他州', ru: 'Северная Дакота', ja: 'サウス・ダコタ' },
  TN: { zh: '田纳西', ru: 'Теннесси', ja: 'テネシー' },
  TX: { zh: '德州', ru: 'Техас', ja: 'テキサス' },
  UT: { zh: '犹他州', ru: 'Юта', ja: 'ユタ州' },
  VT: { zh: '佛蒙特', ru: 'Вермонт', ja: 'バーモント州' },
  VA: { zh: '弗吉尼亚州', ru: 'Виргиния', ja: 'バージニア州' },
  WA: { zh: '华盛顿', ru: 'Вашингтон', ja: 'ワシントン' },
  DC: { zh: '华盛顿特区。', ru: 'Вашингтон, Округ Колумбия', ja: 'ワシントンDC。' },
  WV: { zh: '西弗吉尼亚', ru: 'Западная Виргиния', ja: 'ウェストバージニア州' },
  WI: { zh: '威斯康星', ru: 'Висконсин', ja: 'ウィスコンシン州' },
  WY: { zh: '怀俄明', ru: 'Вайоминг', ja: 'ワイオミング州' },
};
