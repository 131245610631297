import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withCookies } from 'react-cookie';

import { MainMenu } from '@components/MainMenu';
import ContentMenu from '@components/ContentMenu';
import MobileContentMenu from '@components/MobileContentMenu';
import SearchBar from '@components/SearchBar';
import SortBar from '@components/SortBar';
import { Container } from '@components/Container';
import { MobileContainer } from '@components/MobileContainer';
import Loader from '@components/Loader/Loader';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import InternationalRestrictionsList from '@components/Restrictions/InternationalRestrictionsList';

import isEmpty from 'lodash/isEmpty';
import { getSearchData } from 'helpers/getSearchData';
import { menuTabIndexByPathMap } from 'constants/menuTabIndexByPathMap';
import { INTERNATIONAL_PAGE_URI } from 'constants/pageUris';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';

import { Item } from '@components/SortBar/types';
import { InternationalRestrictions, DomesticRestrictions } from 'types/contentful';
import { ISearchItem } from '@components/SearchBar/types';
import { DataExtendedProps, LanguageKey, Tabs } from './types';
import {
  contentMenuItems,
  sortByItem,
  homeSortByItem,
  translationNamesCountries,
  translationNamesStates,
  nonRomanLang,
  translationPlaceholder,
} from './constants';
import { IPageProps } from '../types';

const International = (
  props: IPageProps<(InternationalRestrictions | DomesticRestrictions)[]>,
): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname, origin },
    cookies,
  } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const [searchData, setSearchData] = useState<DataExtendedProps[]>([]);

  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const isMainPage = pathname === '/';

  const sortOptions = isMainPage ? homeSortByItem : sortByItem;

  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortOptions[isMainPage ? 2 : 0]);

  const { regionMenu } = menuTabIndexByPathMap;

  useEffect(() => {
    const getUri = pathname.replace(/\/$/, '');
    const selectedTabValue =
      menuTabIndexByPathMap.regionMenu[uri === '/' ? INTERNATIONAL_PAGE_URI : getUri];

    setSelectedTab(selectedTabValue);
  }, [pathname, uri]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isLoading, searchData]);

  const langCode = (cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME) || '').substr(6, 2);
  const isNonRomanLang = nonRomanLang.test(langCode);
  const placeholderValue =
    translationPlaceholder[langCode as LanguageKey] || translationPlaceholder.en;

  const findTranslationName = useCallback(
    (item: InternationalRestrictions | DomesticRestrictions) => {
      const name = (item as InternationalRestrictions).countryCode
        ? translationNamesCountries[(item as InternationalRestrictions).countryCode]?.[langCode]
        : translationNamesStates[(item as DomesticRestrictions).stateCode]?.[langCode];

      return (isNonRomanLang && name) || '';
    },
    [isNonRomanLang, langCode],
  );

  const allDataExtended =
    pageContext.allData?.map((item: InternationalRestrictions | DomesticRestrictions) => ({
      ...item,
      translationName: findTranslationName(item),
    })) || [];

  const handleSearchData = async (
    toValue: ISearchItem | null,
    fromValue: ISearchItem | null,
    fieldNames: string[],
  ) => {
    setIsLoading(true);

    if (allDataExtended) {
      const searchParams = {
        allData: allDataExtended,
        toValue: toValue?.value || '',
        fromValue: fromValue?.value || '',
        fieldNames,
      };

      const resultSortOption =
        isMainPage &&
        ((!toValue?.value && !fromValue?.value) || (!toValue?.value && fromValue?.value))
          ? homeSortByItem[2]
          : sortByItem[0];

      setSortBySelectedOption(resultSortOption);

      const searchData = getSearchData<DataExtendedProps>(searchParams);
      let newSortedResult = [...searchData];

      if (searchData?.length === 2 && searchData[0].countryName !== toValue?.value) {
        newSortedResult = [searchData[1], searchData[0]];
      }

      setSearchData(newSortedResult);

      setIsSearchUsed(!!toValue || !!fromValue);
    }

    const isSearchEmpty = !toValue && !fromValue;

    setSelectedTab(Tabs[isSearchEmpty ? 'TopDestinations' : 'SearchResults']);

    if (isSearchEmpty) {
      navigate('/');
    }
  };

  const handleSortSelect = (data: DataExtendedProps[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setIsSearchUsed(true);
    setSearchData(data);
  };

  const data = useMemo(() => {
    const res =
      isSearchUsed &&
      searchData.length > 2 &&
      selectedTab === 0 &&
      sortBySelectedOption.fieldName === 'topDestination';

    if (res) {
      return pageContext.data?.map((item: InternationalRestrictions | DomesticRestrictions) => ({
        ...item,
        translationName: findTranslationName(item),
      }));
    }

    return isSearchUsed
      ? searchData
      : pageContext.data?.map((item: InternationalRestrictions | DomesticRestrictions) => ({
          ...item,
          translationName: findTranslationName(item),
        }));
  }, [
    findTranslationName,
    isSearchUsed,
    pageContext,
    searchData,
    selectedTab,
    sortBySelectedOption,
  ]);

  const isSelectedTabHidden = useMemo(() => {
    return data.length <= 2;
  }, [data]);

  const mobileContentMenuItems = useMemo(() => {
    if (!isSelectedTabHidden) {
      return contentMenuItems;
    }

    return [
      ...contentMenuItems,

      {
        label: 'Search results',
        uri: '/search-results',
      },
    ];
  }, [isSelectedTabHidden]);

  const getContent = useCallback(() => {
    const isDataEmptyAndIsSearchUsed = isEmpty(data) && isSearchUsed;

    if (isLoading) {
      return <Loader />;
    }

    if (isDataEmptyAndIsSearchUsed) {
      return <NoCardMatchesFoundMessage />;
    }

    return <InternationalRestrictionsList data={data} url={origin} />;
  }, [data, isLoading, isSearchUsed, origin]);

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname}>
        <MobileContainer>
          <SearchBar
            data={allDataExtended}
            handleSearchData={handleSearchData}
            placeholder={placeholderValue}
            isNonRomanLang={isNonRomanLang}
            isSearchUsed={isSearchUsed}
          />
        </MobileContainer>
      </MainMenu>

      <MobileContentMenu
        {...props}
        items={mobileContentMenuItems}
        selectedTab={selectedTab}
        changeTab={setSelectedTab}
      />

      <ContentMenu
        {...props}
        tabIndexes={regionMenu}
        selectedTab={selectedTab}
        items={contentMenuItems}
        isDesktopOnly
        isSelectedTabHidden={isSelectedTabHidden}
        changeTab={setSelectedTab}
        showMaxLogo
      />
      <Container>
        <SortBar
          data={data}
          showItemsCounter
          showMaxLogo
          {...props}
          options={sortOptions}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
      </Container>

      {getContent()}
    </>
  );
};

export default withCookies(International);
