import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { getCardFormattedDate, createCardExpandHandler, closeCard } from '@components/helpers';
import { classNames } from 'utils/classNames';
import { Cookies, withCookies } from 'react-cookie';

import CardExpandSign from '@components/CardExpandSign';
import { StatusLabel } from '@components/StatusLabel';
import { ExpandContentRegion } from '@components/ExpandContentRegion';
import { ChildMarkdownRemark } from 'types/contentful';
import ForecastView from '@components/ExpandContentRegion/components/ForecastView';
import { currentTrendObj } from '@components/ExpandContentRegion/constatns';
import { Group, Cities, Links } from './types';
import styles from './InternationalCard.module.css';
import {
  GOOGLE_TRANSLATE_COOKIE_NAME,
  noTranslateClassName,
} from '../../LanguageTranslator/constants';

type Props = React.PropsWithChildren<{
  id: string;
  key: string;
  title: string;
  logoUrl?: string | null;
  entryStatus?: string | null;
  updatedAt?: string | null;
  groups: Array<Group>;
  territoryContent: { childMarkdownRemark: ChildMarkdownRemark };
  cities: Cities;
  links: Links;
  translationName: string;
  url: string;
  countryCode: string;
  countryCode3Char?: string;
  stateCode: string;
  totalVaccinated: number;
  vaccinatedPercentage: number;
  approvedVaccines: string;
  isTerritory: boolean;
  vaccination: { childMarkdownRemark: ChildMarkdownRemark };
  currentTrend: 'down' | 'up' | 'center';
  totalCases: number;
  deaths: number;
  recovered: number;
  activeCases: number;
  projectedTimelineEntries: Array<{
    date: string;
    entries: { color: string; textList: string[] }[];
  }>;
  cookies: Cookies | any;
}>;

function InternationalCard(props: Props): React.ReactElement {
  const {
    title,
    translationName,
    url,
    countryCode,
    stateCode,
    totalVaccinated,
    vaccinatedPercentage,
    approvedVaccines,
    isTerritory,
    territoryContent,
    vaccination,
    currentTrend,
    totalCases,
    deaths,
    recovered,
    activeCases,
    projectedTimelineEntries,
  } = props;

  const {
    textHeaderContainer,
    headerContentContainer,
    titleContainer,
    countryContainer,
    cardHeaderContainer,
    openCardTitle,
    titleFlex,
    cardContainer,
    sticky,
    cardHeaderLogo,
    titleMobile,
    statusForecastContainer,
    labelBlock,
    infoContainer,
    cardHeaderDetails,
    cardHeaderTime,
  } = styles;

  const titleStyles = classNames([
    styles.title,
    !props.entryStatus ? titleFlex : '',
    translationName ? noTranslateClassName : '',
  ]);

  const langCode = props?.cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME) || '';

  const isEmptyCard = props.groups.every(it => !it?.categories?.some(item => item.content));

  const [expanded, setExpanded] = useState(false);

  const [isNavigate, setNavigate] = useState(false);
  const handleMouseDown = createCardExpandHandler(setExpanded, expanded);

  const closeCardHandler = useCallback(
    (event: SyntheticEvent): void => {
      closeCard(event, expanded);
    },
    [expanded],
  );
  useEffect(() => {
    if (isNavigate && expanded) {
      const stickyHeaderHeight = 250;

      const elDistanceToTop = window.pageYOffset - stickyHeaderHeight;

      window.scrollTo({ top: elDistanceToTop, behavior: 'smooth' });

      setNavigate(false);
    }
  }, [expanded, isNavigate]);

  const parameter = countryCode ? `?toCountryCode=${countryCode}` : `?stateCode=${stateCode}`;

  return (
    <div className={cardContainer} onMouseDown={handleMouseDown}>
      <div className={expanded ? sticky : ''} onMouseDown={closeCardHandler}>
        <div className={classNames([cardHeaderContainer, expanded ? openCardTitle : ''])}>
          <div className={textHeaderContainer}>
            <CardExpandSign isExpanded={expanded} />

            <div className={headerContentContainer}>
              <div className={titleContainer}>
                <div className={countryContainer}>
                  {props.logoUrl && (
                    <div className={cardHeaderLogo}>
                      <img alt="logo" src={props.logoUrl} />
                    </div>
                  )}
                  <h3 className={titleStyles}>{translationName || title}</h3>
                  <h3
                    className={classNames([
                      titleMobile,
                      translationName ? noTranslateClassName : '',
                    ])}
                  >
                    {translationName || title}
                  </h3>
                </div>
                <div className={statusForecastContainer}>
                  {props.entryStatus && (
                    <StatusLabel
                      labelStyles={styles.label}
                      status={props.entryStatus}
                      type="country"
                      langCode={langCode}
                    />
                  )}
                  <div className={labelBlock}>
                    <ForecastView currentTrend={currentTrendObj[currentTrend]} />
                  </div>
                </div>
              </div>
              <div className={infoContainer}>
                <div className={cardHeaderDetails}>
                  {props.updatedAt && !isTerritory && (
                    <div className={cardHeaderTime}>
                      Updated &nbsp;{getCardFormattedDate(props.updatedAt)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(!isEmptyCard || isTerritory) && (
        <ExpandContentRegion
          isCardExpanded={expanded}
          groups={props.groups}
          cities={props.cities}
          links={props.links}
          parentId={props.id}
          title={title}
          url={url}
          totalVaccinated={totalVaccinated}
          vaccinatedPercentage={vaccinatedPercentage}
          approvedVaccines={approvedVaccines}
          parameter={parameter}
          isTerritory={isTerritory}
          territoryContent={territoryContent}
          vaccination={vaccination}
          currentTrend={currentTrend}
          totalCases={totalCases}
          deaths={deaths}
          recovered={recovered}
          activeCases={activeCases}
          projectedTimelineEntries={projectedTimelineEntries}
        />
      )}
    </div>
  );
}

export default withCookies(InternationalCard);
