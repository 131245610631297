import React from 'react';

import styles from './NoCardMatchesFoundMessage.module.css';

const NoCardMatchesFoundMessage = (): React.ReactElement => {
  return (
    <div className={styles.noCardMatchesFoundContainer}>
      <span className={styles.noCardMatchesFoundMessage}>
        No matches found. Please refine search.
      </span>
    </div>
  );
};

export default NoCardMatchesFoundMessage;
