type GenericObject = Record<string, any>;

export const sortByFieldNameDesc = (fieldName: string) => (
  a: GenericObject,
  b: GenericObject,
): number => {
  if (a[fieldName] === b[fieldName]) {
    return 0;
  }
  if (a[fieldName] === null) {
    return 1;
  }
  if (b[fieldName] === null) {
    return -1;
  }

  return a[fieldName] < b[fieldName] ? 1 : -1;
};

export const sortByFieldNameAsc = (fieldName: string) => (
  a: GenericObject,
  b: GenericObject,
): number => {
  if (a[fieldName] === b[fieldName]) {
    return 0;
  }
  if (a[fieldName] === null) {
    return 1;
  }
  if (b[fieldName] === null) {
    return -1;
  }

  return a[fieldName] > b[fieldName] ? 1 : -1;
};
