/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { IStatusTooltips } from './types';

export const statuses: { [key: string]: string } = {
  Extreme: 'Extreme',
  High: 'High',
  Medium: 'Medium',
  Moderate: 'Moderate',
  Low: 'Low',
  NotInService: 'Not in service',
  None: '',
};

export const statusTooltips: IStatusTooltips = {
  country: {
    Extreme: {
      header: 'Extreme COVID-19 Infections Risk Level:',
      text: '100+ of new cases in the last 14 days per 100,000 population',
    },
    High: {
      header: 'High COVID-19 Infections Risk Level:',
      text: '51-100 of new cases in the last 14 days per 100,000 population',
    },
    Medium: {
      header: 'Medium COVID-19 Infections Risk Level:',
      text: '11-50 of new cases in the last 14 days per 100,000 population',
    },
    Moderate: {
      text:
        'These locations have little to no restrictions on international and domestic travel as well as internal movement.',
    },
    Low: {
      header: 'Low COVID-19 Infections Risk Level:',
      text: '0-10 of new cases in the last 14 days per 100,000 population',
    },
  },
  state: {
    Extreme: { text: 'Travel and movement only allowed for essential tasks and medical care.' },
    High: { text: 'Some intra-region travel is allowed, but stay-at-home is widely encouraged.' },
    Medium: {
      text: 'Mostly free intra-region travel, with limited travel between regions allowed.',
    },
    Moderate: { text: 'Intra- and Inter-region travel allowed.' },
    Low: { text: 'Not currently used.' },
  },
  airline: {
    NotInService: {
      text:
        'This airline currently has suspended all flights. \n' +
        'Please use original source  for more details.',
    },
  },
};
