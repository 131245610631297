import React from 'react';

export default function ReverseIcon(props: any) {
  const { width = 32, height = 32, fill = '#3763A0' } = props;

  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 8L19.59 9.41L22.17 12H15V14H22.17L19.59 16.59L21 18L26 13L21 8Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M11 14L12.41 15.41L9.83 18H17V20H9.83L12.41 22.59L11 24L6 19L11 14Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
