import React from 'react';
import { InternationalCard } from '@components/Restrictions/InternationalCard';

import { DataExtendedProps } from '@templates/international/types';
import { getInternationalItemProps } from './helpers';

type Props = {
  data?: DataExtendedProps[];
  url: string;
};

function InternationalList(props: Props): React.ReactElement {
  return (
    <>
      {props.data?.map((item: DataExtendedProps) => (
        <InternationalCard {...getInternationalItemProps(item, props.url)} />
      )) ?? []}
    </>
  );
}

export default InternationalList;
