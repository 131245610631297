import { DataExtendedProps } from '@templates/international/types';
import { ActionMeta, Theme } from 'react-select';

export interface ISearchItem {
  label?: string;
  value?: string;
  stateCode?: string;
}

export interface IFieldData {
  toFieldData: ISearchItem | null;
  fromFieldData: ISearchItem | null;
}

export interface ISearchBarProps {
  placeholder: string;
  handleSearchData: (
    toValue: ISearchItem | null,
    fromValue: ISearchItem | null,
    fieldName: string[],
  ) => void;
  data: DataExtendedProps[];
  isNonRomanLang: boolean;
  isSearchUsed: boolean;
}

export interface ISearchBarViewProps {
  memoizeCreateSelectStyles: any;
  memoizeSetThemeInputColor: (theme: Theme) => Theme;
  placeholder: string;
  fieldData: IFieldData;
  handleFieldInputChange: (value: ISearchItem, action: ActionMeta<ISearchItem>) => void;
  items: ISearchItem[];
  searchClassName: string;
  toSelectRef: any;
  fromSelectRef: any;
  reverseHandler: () => void;
}

export enum FieldNames {
  To = 'to',
  From = 'from',
}

export enum ActionClear {
  Clear = 'clear',
}
