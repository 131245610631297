import React from 'react';

import { classNames } from 'utils/classNames';

import styles from './MaxSecurityIcon.module.css';

type MaxSecurityIconProps = {
  className?: string;
};

const MaxSecurityIcon = ({ className = '' }: MaxSecurityIconProps): React.ReactElement => (
  <svg viewBox="0 0 28 28" className={styles.maxSecurityIcon}>
    <path
      className={classNames([styles.iconPart, className])}
      d="M20.7,13.6l-2.1-2.7h2.6l0.8,1.1l3.9-5C23.5,2.8,19,0,13.9,0C6.2,0,0,6.2,0,13.9
            c0,6.3,4.2,11.7,10,13.3L20.7,13.6z M13,10.9h3.3l1.8,5.3h-2.3l-0.3-0.9h-1.7l-0.3,
            0.9h-2.3L13,10.9z M7.3,16.1H5.7l-0.6-2.2l-0.6,2.2H2.5l1.3-5.3H6L6.5,13L7,10.9h2.1
            l1.3,5.3H8.4l-0.6-2.2L7.3,16.1z"
    />
    <polygon
      className={classNames([styles.iconPart, className])}
      points="14.7,12.7 14.7,12.7 14.3,14 15.1,14"
    />
    <path
      className={classNames([styles.iconPart, className])}
      d="M23.8,10.9h1.5l-2.1,2.6l0,0l0,0l2.1,2.6h-2.6l-0.8-1l-0.8,1h-1.5l-8.9,11.3
            c1,0.2,2,0.3,3.1,0.3c7.7,0,13.9-6.2,13.9-13.9c0-2.2-0.5-4.3-1.5-6.2L23.8,10.9z"
    />
  </svg>
);

export default MaxSecurityIcon;
