import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { handleMenuTabSelect } from '@components/helpers';

import { getTranstlatedText } from 'helpers/getTranslatedText';
import { withCookies } from 'react-cookie';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import MaxSecurity from '@components/MaxSecurityLogo/MaxSecurityLogo';
import { classNames } from '../../utils/classNames';
import styles from './ContentMenu.module.css';

import { IProps } from './types';

export const ContentMenu = (props: IProps): React.ReactElement => {
  const {
    items,
    isDesktopOnly,
    selectedTab,
    uri,
    tabIndexes,
    showSuppliersCaveat,
    location: { pathname: staticUri },
    cookies,
    isSelectedTabHidden,
    showMaxLogo = false,
  } = props;

  const selectedTabValue = !isSelectedTabHidden
    ? selectedTab ?? tabIndexes[uri === '/' ? staticUri : uri]
    : -1;

  const lang = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  return (
    <div
      className={classNames([
        styles.container,
        isDesktopOnly ? styles.desktopOnly : '',
        showSuppliersCaveat ? styles.box : '',
      ])}
    >
      <Tabs
        className={styles.tabs}
        selectedTabClassName={styles.tabsSelected}
        selectedIndex={selectedTabValue}
        onSelect={handleMenuTabSelect(props)}
      >
        <TabList className={styles.tabList}>
          {items.map(item => (
            <Tab key={item.label} className={styles.tabsTab} data-uri={item.uri}>
              {getTranstlatedText(item.label, lang)}
            </Tab>
          ))}
        </TabList>
        {items.map(item => (
          <TabPanel
            key={item.label}
            selectedClassName={styles.tabPanelSelected}
            className={styles.tabPanel}
          >
            {props.children}
          </TabPanel>
        ))}
      </Tabs>
      {showMaxLogo && (
        <div
          style={{
            marginTop: '35px',
            minWidth: '110px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            marginRight: '21px',
          }}
        >
          <MaxSecurity className={styles.maxSecurityWrapper} />
        </div>
      )}
    </div>
  );
};

export default withCookies(ContentMenu);
