const baseText = 'COVID-19 status trend is';

export const currentTrendObj = {
  up: {
    className: 'currentTrendUp',
    text: 'Decreasing',
    tooltipText: `${baseText} improving - Active cases are dropping`,
  },
  down: {
    className: 'currentTrendDown',
    text: 'Increasing',
    tooltipText: `${baseText} deteriorating - Active cases are increasing`,
  },
  center: {
    className: 'currentTrendCenter',
    text: 'Stable',
    tooltipText: `${baseText} stable - Active cases are stable`,
  },
};
