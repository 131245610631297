import React from 'react';

import styles from './Container.module.css';

type Props = {
  children?: React.ReactElement[] | React.ReactElement;
};

const Container = (props: Props): React.ReactElement => (
  <div className={styles.container}>{props.children}</div>
);

export default Container;
