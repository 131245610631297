import React from 'react';
import styles from './MobileContainer.module.css';

interface Props {
  children?: React.ReactElement[] | React.ReactElement;
  title?: string;
}

function MobileContainer(props: Props): React.ReactElement {
  const { children, title } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
}

export default MobileContainer;
