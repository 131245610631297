import React from 'react';
import Select, { ActionMeta, ValueType } from 'react-select';

import { ClearIndicator } from '@components/SearchBar/components/ClearIndicator';
import { noOption } from '@components/SearchBar';
import ReverseIcon from '@components/icons/Reverse';

import { FieldNames, ISearchBarViewProps, ISearchItem } from '@components/SearchBar/types';

import styles from './SearchBar.module.css';

const SearchBarView = (props: ISearchBarViewProps) => {
  const {
    memoizeCreateSelectStyles,
    memoizeSetThemeInputColor,
    fieldData,
    handleFieldInputChange,
    items,
    placeholder,
    searchClassName,
    toSelectRef,
    fromSelectRef,
    reverseHandler,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.control}>
        <span className={styles.text}>I am traveling to</span>

        <div className={styles.selectContainer}>
          <Select
            theme={memoizeSetThemeInputColor}
            styles={memoizeCreateSelectStyles}
            isClearable
            value={fieldData.toFieldData}
            name={FieldNames.To}
            onChange={(item: ValueType<ISearchItem>, action: ActionMeta<ISearchItem>) =>
              handleFieldInputChange(item as ISearchItem, action as ActionMeta<ISearchItem>)
            }
            options={items}
            placeholder={placeholder}
            className={searchClassName}
            noOptionsMessage={noOption}
            components={{ ClearIndicator }}
            ref={toSelectRef}
          />
          <button type="button" onClick={reverseHandler} className={styles.reverse}>
            <ReverseIcon />
          </button>
        </div>
      </div>

      <div className={styles.control}>
        <span className={styles.text}>From</span>
        <Select
          theme={memoizeSetThemeInputColor}
          styles={memoizeCreateSelectStyles}
          isClearable
          value={fieldData.fromFieldData}
          name={FieldNames.From}
          onChange={(item: ValueType<ISearchItem>, action: ActionMeta<ISearchItem>) =>
            handleFieldInputChange(item as ISearchItem, action)
          }
          options={items}
          placeholder={placeholder}
          isSearchable={true}
          className={searchClassName}
          noOptionsMessage={noOption}
          components={{ ClearIndicator }}
          ref={fromSelectRef}
        />
      </div>
    </div>
  );
};

export default SearchBarView;
