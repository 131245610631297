import { InternationalCard } from '@components/Restrictions/InternationalCard';
import { DataExtendedProps } from '@templates/international/types';
import { DomesticRestrictions, InternationalRestrictions } from 'types/contentful';

const prepareTimeLines = (timelines: Array<[string, [{ color: string; text: string }]]>) =>
  timelines.map(entry => {
    const values = entry[1];

    const colorSplit: { [key: string]: string[] } = {};

    values.forEach(({ color, text }) => {
      const current = colorSplit?.[color] || [];
      current.push(text);

      colorSplit[color] = current;
    });

    const result = Object.keys(colorSplit).map(color => ({
      color,
      textList: colorSplit[color],
    }));

    return { date: entry[0], entries: result };
  });

export const getInternationalItemProps = (
  item: DataExtendedProps,
  url: string,
): Parameters<typeof InternationalCard>[0] => ({
  groups: [
    {
      title: 'Regulations',
      categories: [
        {
          content: item.regulationsEssential,
          title: 'Essential Traveler Requirements & Stipulations',
          classImg: 'essentialTravelerRequirementsImg',
        },
        {
          content: item.regulationsMandatoryTesting,
          title: 'Mandatory Testing',
          classImg: 'regulationsMandatoryTestingImg',
        },
        {
          content: item.regulationsQuarantine,
          title: 'Quarantine Upon Entry',
          classImg: 'regulationsQuarantineImg',
        },
        {
          content: item.regulationsHealthCertifications,
          title: 'Health Certifications',
          classImg: 'regulationsHealthCertificationsImg',
        },
        {
          content: item.regulationsMasks,
          title: 'Masks - Yes',
          classImg: 'regulationsMasksImg',
        },
        {
          content: item.regulationsContainment,
          title: 'Containment Measures',
          classImg: 'regulationsContainmentImg',
        },
        {
          content: item.regulationsCurfew,
          title: 'Areas Under Curfew',
          classImg: 'regulationsCurfewImg',
        },
      ],
    },
  ],
  cities: {
    title: 'Cities',
    categories: {
      content: item.cities,
      title: 'Cities',
      classImg: 'citiesImg',
    },
  },
  links: {
    title: 'Links',
    content: item.links,
  },
  title: item.countryName,
  updatedAt: item.updatedAt,
  entryStatus: item.entryStatus,
  logoUrl: item.flagImage?.file.url,
  key: item.id,
  id: item.id,
  translationName: item.translationName,
  url,
  countryCode: (item as InternationalRestrictions).countryCode,
  countryCode3Char: (item as InternationalRestrictions).countryCode3Char,
  stateCode: (item as DomesticRestrictions).stateCode,
  totalVaccinated: item.totalVaccinated,
  vaccinatedPercentage: item.vaccinatedPercentage,
  approvedVaccines: item.approvedVaccines,
  isTerritory: item.isTerritory || false,
  territoryContent: item.covidOverview,
  vaccination: item.vaccination,
  currentTrend: item.currentTrend,
  totalCases: item.totalCases,
  deaths: item.deaths,
  recovered: item.recovered,
  activeCases: item.activeCases,
  projectedTimelineEntries: prepareTimeLines(
    Object.entries(JSON.parse(item.projectedTimeline?.internal?.content || '[]')),
  ),
});
