import {
  DomesticDataExtendedProps,
  InternationalDataExtendedProps,
  TranslationNameCountryCode,
} from '@templates/international/types';
import { ISearchItem } from './types';

export const createDropDownItems = (data: TranslationNameCountryCode[]): ISearchItem[] =>
  data.map(props => {
    const { translationName, countryName } = props;
    const { countryCode3Char } = props as InternationalDataExtendedProps;
    const { stateCode } = props as DomesticDataExtendedProps;

    const name = translationName || countryName;

    return {
      label: name,
      value: name,
      countryCode3Char,
      stateCode,
    };
  });
