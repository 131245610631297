import { InternationalRestrictions, DomesticRestrictions } from 'types/contentful';

export type DataExtendedProps = InternationalDataExtendedProps | DomesticDataExtendedProps;

export type InternationalDataExtendedProps = InternationalRestrictions & {
  translationName: string;
};

export type DomesticDataExtendedProps = DomesticRestrictions & {
  translationName: string;
  countryCode?: string;
};

export type TranslationNameCountryCode = InternationalDataExtendedProps | DomesticDataExtendedProps;

export type LanguageKey = 'ja' | 'ru' | 'zh';

export enum Tabs {
  TopDestinations,
  USStates,
  Americas,
  Europe,
  Africa,
  MENA,
  Asia,
  SearchResults,
}
