/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */

import React, { useCallback, SyntheticEvent } from 'react';
import { classNames } from 'utils/classNames';

import SocialShare from '@components/SocialShare';

import { removeTitleFromHTML } from './helpers';

import { ExpandContentRegionProps } from './types';

import styles from './ExpandContentRegion.module.css';
import stylesIcon from './ExpandContentRegionIcon.module.css';

const ExpandContentRegion = (props: ExpandContentRegionProps): React.ReactElement => {
  const {
    totalVaccinated,
    vaccinatedPercentage,
    approvedVaccines,
    isCardExpanded,
    groups,
    cities,
    parentId,
    title,
    url,
    parameter,
    links,
    isTerritory,
    territoryContent,
    vaccination,
    totalCases,
    deaths,
    recovered,
    activeCases,
    projectedTimelineEntries,
  } = props;
  const citiesArray = Object.entries(
    JSON.parse(cities?.categories?.content?.internal?.content || '[]'),
  );

  const handleOpenInNewWindow = useCallback((e: SyntheticEvent): void => {
    const link = (typeof Element.prototype.closest === 'function'
      ? (e.target as Element).closest('a')
      : (e.target as Element).parentNode) as HTMLAnchorElement;

    if (link) {
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    }
  }, []);

  return (
    <div
      className={classNames([styles.container, isCardExpanded ? styles.expanded : ''])}
      data-close-content="false"
      onClick={handleOpenInNewWindow}
    >
      <div className={styles.openPart}>
        <div className={styles.vaccinationContainer}>
          {!isTerritory && (
            <>
              <div className={styles.subtitleContainer}>
                <span
                  className={classNames([stylesIcon.vaccinationImg, stylesIcon.icon, styles.icon])}
                />

                <h3 className={styles.subtitle}>Vaccination</h3>
              </div>

              <div className={styles.text}>
                Total fully vaccinated:
                <span className={styles.value}>
                  &nbsp;{totalVaccinated?.toLocaleString('en') || 0} / {vaccinatedPercentage || 0}%
                </span>
              </div>

              {approvedVaccines && (
                <div className={styles.text}>
                  Vaccines administered: <span className={styles.value}>{approvedVaccines}</span>
                </div>
              )}
            </>
          )}

          <div className={styles.openMore}>More details</div>
        </div>
      </div>
      <div className={styles.clearfix}>
        {isCardExpanded && (
          <ul className={styles.itemList}>
            {!!isTerritory && (
              <li className={styles.item}>
                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{
                    __html: removeTitleFromHTML(territoryContent?.childMarkdownRemark?.html || ''),
                  }}
                />
              </li>
            )}

            {!!vaccination && (
              <li className={classNames([styles.item, styles.categoryItem])}>
                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{
                    __html: removeTitleFromHTML(vaccination?.childMarkdownRemark?.html || ''),
                  }}
                />
              </li>
            )}
            {!!projectedTimelineEntries.length && (
              <li className={classNames([styles.item, styles.categoryItem])}>
                <div className={styles.subtitleContainer}>
                  <span
                    className={classNames([stylesIcon.announcement, stylesIcon.icon, styles.icon])}
                  />
                  <h3 className={styles.subtitle}>Upcoming</h3>
                </div>
                <div className={styles.timeLines}>
                  {projectedTimelineEntries?.map(item =>
                    item.entries.map(inner => (
                      <div className={styles.timeLine} key={inner.color + item.date}>
                        <div className={classNames([styles[inner.color], styles.timeLineLabel])}>
                          <span
                            className={classNames([
                              stylesIcon.calendar,
                              stylesIcon.icon,
                              styles.icon,
                            ])}
                          />
                          {item.date
                            .replace(/([a-z]{3})\s(\d{4})(\d{1,2})/i, '$1 $3')
                            .split('-')
                            ?.join(' - ')}
                        </div>

                        <div className={styles.timeLineText}>
                          {inner.textList.map((txt, i) => {
                            if (i === inner.textList.length - 1) {
                              return txt;
                            }

                            return (
                              <React.Fragment key={txt.trim().slice(0, 5)}>
                                {txt} <hr className={styles.divider} />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    )),
                  )}
                </div>
              </li>
            )}

            {!!totalCases && !!deaths && !!recovered && !!activeCases && (
              <li className={classNames([styles.item, styles.categoryItem])}>
                <div className={styles.subtitleContainer}>
                  <span
                    className={classNames([stylesIcon.overviewImg, stylesIcon.icon, styles.icon])}
                  />
                  <h3 className={styles.subtitle}>Cases overview</h3>
                </div>

                <div className={styles.statisticsContent}>
                  <div className={styles.statisticsItem}>
                    <span className={styles.statisticsText}>Total cases</span>
                    <span className={styles.numberOfCases}>{totalCases}</span>
                  </div>

                  <div className={styles.statisticsItem}>
                    <span className={styles.statisticsText}>Recovered</span>
                    <span className={styles.numberOfCases}>{recovered}</span>
                  </div>

                  <div className={styles.statisticsItem}>
                    <span className={styles.statisticsText}>Active cases</span>
                    <span className={styles.numberOfCases}>{activeCases}</span>
                  </div>

                  <div className={styles.statisticsItem}>
                    <span className={styles.statisticsText}>Deaths</span>
                    <span className={styles.numberOfCases}>{deaths}</span>
                  </div>
                </div>
              </li>
            )}

            {groups
              .filter(group => group?.categories?.some(category => category.content))
              .map(({ title, categories }) => (
                <li
                  key={`${parentId}_${title}`}
                  className={`${styles.item} ${
                    isCardExpanded ? styles.itemExpanded : styles.itemCollapsed
                  }`}
                >
                  <h2 className={styles.title}>{title}</h2>

                  <ul className={styles.list}>
                    {categories
                      ?.filter(item => item?.content)
                      .map(({ title, content, classImg }) => (
                        <li key={`${parentId}_${title}`} className={styles.categoryItem}>
                          {content?.childMarkdownRemark?.html && (
                            <>
                              {title !== 'Overview' && (
                                <div className={styles.subtitleContainer}>
                                  {!!classImg && (
                                    <span
                                      className={`${stylesIcon.icon} ${styles.icon} ${stylesIcon[classImg]}`}
                                    />
                                  )}
                                  <h3 className={styles.subtitle}>{title}</h3>
                                </div>
                              )}
                              <div
                                className={styles.itemContent}
                                dangerouslySetInnerHTML={{
                                  __html: removeTitleFromHTML(content?.childMarkdownRemark?.html),
                                }}
                              />
                            </>
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}

            {citiesArray[0] && (
              <li
                key={`${parentId}_cities`}
                className={classNames([
                  styles.item,
                  isCardExpanded ? styles.itemExpanded : styles.itemCollapsed,
                ])}
              >
                <h2 className={styles.title}>{cities.title}</h2>
                {!!isCardExpanded && (
                  <ul className={styles.list}>
                    {citiesArray?.map(city => (
                      <li key={`${parentId}_${city[0]}`} className={styles.categoryItem}>
                        <span
                          className={classNames([
                            stylesIcon.citiesImg,
                            stylesIcon.icon,
                            styles.icon,
                          ])}
                        />

                        <h3 className={styles.subtitle}>{city[0]}</h3>

                        <div
                          className={styles.itemContent}
                          dangerouslySetInnerHTML={{
                            __html: city[1] as string,
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}

            {!!isCardExpanded && links.content && (
              <li
                key={`${parentId}_links`}
                className={`${styles.item} ${styles.links} ${
                  isCardExpanded ? styles.itemExpanded : styles.itemCollapsed
                }`}
              >
                <h2 className={styles.title}>{links.title}</h2>

                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{
                    __html: links.content.childMarkdownRemark?.html,
                  }}
                />
              </li>
            )}
          </ul>
        )}
      </div>
      <div className={styles.socialBlock}>
        <SocialShare title={title} url={url + parameter} id={parentId} />
      </div>
    </div>
  );
};

export default ExpandContentRegion;
