const commonSelectStyles = {
  container: base => ({
    ...base,
    margin: '8px 0 0',
    '@media (max-width: 720px)': {
      font: '400 14px/19px "Proxima Nova", sans-serif',
      margin: '8px 0 0',
    },
  }),
  placeholder: base => ({
    ...base,
    font: '400 16px/19px "Proxima Nova", sans-serif',
    color: 'rgba(105, 104, 110, 0.8)',

    '@media (max-width: 720px)': {
      font: '400 13px/14px "Proxima Nova", sans-serif',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      position: 'static',
      transform: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    '& > svg': {
      display: 'none',
    },
  }),
  singleValue: base => ({
    ...base,
    padding: 0,
    font: '300 16px/19px "Proxima Nova", sans-serif',
    color: '#404040',
    '@media (max-width: 720px)': {
      font: '300 14px/19px "Proxima Nova", sans-serif',
    },
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 19px 0 0',
    font: '300 16px/19px "Proxima Nova", sans-serif',
    '@media (max-width: 720px)': {
      font: '300 14px/19px "Proxima Nova", sans-serif',
      flexWrap: 'nowrap',
      width: '100%',
    },
  }),
  input: base => ({
    ...base,
    '& input': {
      font: '300 16px/19px "Proxima Nova", sans-serif',
      color: '#404040',
      '@media (max-width: 720px)': {
        font: '300 14px/19px "Proxima Nova", sans-serif',
      },
    },
  }),
  indicatorsContainer: base => ({
    ...base,
    cursor: 'pointer',
    '& div': {
      cursor: 'pointer',
    },
    '& div:last-child': {
      display: 'none',
    },
    '& img': {
      width: 40,
      height: 40,
    },
  }),
  menuList: base => ({
    ...base,
    maxHeight: 220,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#B9C7D4' : 'white',
    font: '300 16px/19px "Proxima Nova", sans-serif',
    color: !state.isFocused ? '#5A88C6' : '#404040',
    '@media (max-width: 720px)': {
      font: '300 14px/19px "Proxima Nova", sans-serif',
    },
  }),
};

export const ASTRONAUT = '#25426A';
export const ALUMINIUM = '#aaaeb3';

const createControlStyles = state => {
  const { isFocused, menuIsOpen } = state;

  const activeBorderColor = isFocused || menuIsOpen ? ASTRONAUT : ALUMINIUM;

  return {
    height: 48,
    padding: '0 0 0 19px',
    borderColor: activeBorderColor,
    boxShadow: 'none',
    font: '400 16px/19px "Proxima Nova", sans-serif',
    color: '#404040',
    cursor: 'text',
    '@media (max-width: 720px)': {
      paddingLeft: 7,
      paddingRight: 0,
      font: '400 14px/19px "Proxima Nova", sans-serif',
    },
  };
};

export const createSelectStyles = () => ({
  ...commonSelectStyles,
  control: (base, state) => ({
    ...base,
    ...createControlStyles(state),
    borderWidth: 1,
    borderRadius: 8,
  }),
});
