type SearchParams<T> = {
  allData: T[];
  toValue: string;
  fromValue?: string;
  fieldNames?: string[];
};

const findMatched = <T>({ allData, fieldNames, toValue, fromValue }: SearchParams<T>): T[] => {
  return allData.filter(
    item =>
      fieldNames?.filter((key: string) => {
        const base = item[key]?.toLowerCase();

        return (
          (toValue && base && base === toValue?.toLowerCase()) ||
          (fromValue && base && base === fromValue?.toLowerCase())
        );
      }).length,
  );
};

export const getSearchData = <T>(params: SearchParams<T>): T[] => {
  const { allData, toValue, fromValue, fieldNames } = params;
  const toFilterResult = findMatched({
    allData,
    fieldNames,
    toValue,
    fromValue,
  });

  return toFilterResult;
};
