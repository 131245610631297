import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ActionMeta, Theme } from 'react-select';

import { StringParam, useQueryParam } from 'use-query-params';

import SearchBarView from '@components/SearchBar/SearchBarView';

import { createDropDownItems } from '@components/SearchBar/helpers';

import { noTranslateClassName } from '@components/LanguageTranslator/constants';
import { NO_OPTIONS } from 'constants/searchBar';

import {
  ActionClear,
  FieldNames,
  IFieldData,
  ISearchBarProps,
  ISearchItem,
} from '@components/SearchBar/types';

import { ASTRONAUT, createSelectStyles } from '@components/SearchBar/SearchBarSelectStyles';

export const noOption = (): string => NO_OPTIONS;

const SearchBar = (props: ISearchBarProps): JSX.Element => {
  const { placeholder, isNonRomanLang, isSearchUsed, handleSearchData, data } = props;

  const items = createDropDownItems(data);

  const toSelectRef = useRef<any>();

  const fromSelectRef = useRef<any>();

  const [fieldData, setFieldData] = useState<IFieldData>({
    toFieldData: null,
    fromFieldData: null,
  });

  const [toCountryCode, setToCountryCode] = useQueryParam('toCountryCode', StringParam);

  const [fromCountryCode, setFromCountryCode] = useQueryParam('fromCountryCode', StringParam);

  const [stateCode, setStateCode] = useQueryParam('stateCode', StringParam);

  const searchClassName = useMemo(() => (isNonRomanLang ? noTranslateClassName : ''), [
    isNonRomanLang,
  ]);

  const onSearch = useCallback(
    (toFieldValue: ISearchItem | null, fromFieldValue: ISearchItem | null): void => {
      setToCountryCode(undefined);
      setFromCountryCode(undefined);
      setStateCode(undefined);

      handleSearchData(toFieldValue, fromFieldValue, ['countryName', 'translationName']);
    },
    [setToCountryCode, setFromCountryCode, setStateCode, handleSearchData],
  );

  const handleFieldInputChange = useCallback(
    (value: ISearchItem, action: ActionMeta<ISearchItem>): void => {
      const { action: actionSelect = null, name = null } = action || {};

      const to = name === FieldNames.To && name;
      const from = name === FieldNames.From && name;

      const isActionSelectClear = actionSelect === ActionClear.Clear;

      if (value?.value && to) {
        setFieldData({ ...fieldData, toFieldData: value });

        fromSelectRef.current?.focus();

        onSearch(value, fieldData.fromFieldData);
      }
      if (value?.value && from) {
        setFieldData({ ...fieldData, fromFieldData: value });

        toSelectRef.current?.focus();

        onSearch(fieldData.toFieldData, value);
      }

      if (isActionSelectClear && to) {
        setFieldData({ ...fieldData, toFieldData: null });

        onSearch(value, fieldData.fromFieldData);
      }
      if (isActionSelectClear && from) {
        setFieldData({ ...fieldData, fromFieldData: null });

        onSearch(fieldData.toFieldData, value);
      }
    },
    [fieldData, onSearch],
  );

  const reverseHandler = useCallback(() => {
    setFieldData({ toFieldData: fieldData.fromFieldData, fromFieldData: fieldData.toFieldData });

    if (!!fieldData.toFieldData && !!fieldData.fromFieldData) {
      onSearch(fieldData.fromFieldData, fieldData.toFieldData);
    }
  }, [fieldData.fromFieldData, fieldData.toFieldData, onSearch]);

  const memoizeCreateSelectStyles = useMemo(() => createSelectStyles(), []);

  const memoizeSetThemeInputColor = useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: ASTRONAUT,
      },
    }),
    [],
  );

  useEffect(() => {
    if (stateCode) {
      const stateValue = { value: stateCode || '' };

      setStateCode(undefined);

      handleSearchData(stateValue, null, ['stateCode']);
    }
  }, [handleSearchData, setStateCode, stateCode]);

  useEffect(() => {
    if (!isSearchUsed) {
      setFieldData({ toFieldData: null, fromFieldData: null });
    }
  }, [isSearchUsed]);

  useEffect(() => {
    if (toCountryCode || fromCountryCode) {
      const fromValue = { value: fromCountryCode || '' };
      const toValue = { value: toCountryCode || '' };

      setToCountryCode(undefined);
      setFromCountryCode(undefined);

      handleSearchData(toValue, fromValue, ['countryCode']);
    }
  }, [toCountryCode, fromCountryCode, handleSearchData, setToCountryCode, setFromCountryCode]);

  return (
    <SearchBarView
      memoizeCreateSelectStyles={memoizeCreateSelectStyles}
      memoizeSetThemeInputColor={memoizeSetThemeInputColor}
      fieldData={fieldData}
      handleFieldInputChange={handleFieldInputChange}
      items={items}
      placeholder={placeholder}
      searchClassName={searchClassName}
      toSelectRef={toSelectRef}
      fromSelectRef={fromSelectRef}
      reverseHandler={reverseHandler}
    />
  );
};

export default SearchBar;
