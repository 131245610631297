import React from 'react';

import { IIconProps } from './types';

import styles from './Arrow.module.css';

const Arrow = ({ className }: IIconProps): JSX.Element => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    className={styles[className]}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11Z" />
  </svg>
);

export default Arrow;
